(function (w) {
  var els = document.querySelectorAll('[data-dgk-merchant]');
  var iframes = [];
  //eslint-disable-next-line
  //console.log(els);
  for (var i = 0; i < els.length; i++) {
    var el = els[i];
    var settings = {};
    settings.merchant = el.getAttribute('data-dgk-merchant');
    settings.base = el.getAttribute('data-dgk-base') || null;
    settings.layout = el.getAttribute('data-dgk-layout') || 'default';
    settings.type = el.getAttribute('data-dgk-type') || 'shop';
    if (typeof window.dgkWebshopData === 'string') {
      settings.webshopData = JSON.parse(atob(window.dgkWebshopData))
    }
    var iframe = document.createElement('iframe');
    iframe.src = (process.env.NODE_ENV === 'production' ? 'https://api.danskegavekort.dk/integration/v2/' : 'index.html?') + settings.merchant;
    iframe.style.border = 'none'; //DO NOT ADD BORDER HERE - iframe may be much larger than content!
    iframe.style.height = settings.type === 'balance' ? '50px' : '300px';
    iframe.style.width = '600px';
    iframe.style.maxWidth = '100%';
    iframe.style.display = 'block';
    iframe.style.margin = '0 auto';
    iframe.style.overflow = 'hidden';
    iframe.scrolling = 'no';
    el.appendChild(iframe);
    iframes[i] = iframe;

    (function (i, settings, iframe) {
      iframe.addEventListener('load', function () {
        iframe.contentWindow.postMessage({
          action: 'setInfo',
          el: i,
          url: w.location.protocol + '//' + w.location.hostname + ':' + w.location.port,
          fullUrl: w.location.href,
          hash: w.location.hash,
          settings: settings,
        }, iframe.src);
      });
    })(i, settings, iframe);
  }
  w.setDGKdesign = function(design) {
    for (var i = 0; i < iframes.length; i++) {
      let iframe = iframes[i]
      iframe.contentWindow.postMessage({
        action: 'setDesign',
        design: design,
      }, iframe.src);
    }
  }
  w.addEventListener("message", function (e) {
    //console.log(e.data, typeof e.data.el);
    if (
      typeof e.data === 'object'
      && typeof e.data.el === 'number'
      && typeof e.data.action === 'string') {
      switch (e.data.action) {
        case 'setHeight':
          iframes[e.data.el].style.height = e.data.height + 'px';
          break;
        case 'scrollTo':
          var top = Math.max(0, findTop(iframes[e.data.el]) + e.data.top - 100)
          window.scrollTo({
            top: top,
            behavior: 'smooth',
          })
          break;
        case 'setHash':
          window.location.hash = e.data.hash;
          break;
        case 'dgkScrollBack':
          iframes[e.data.el].scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
          break;
      }
    }
  }, false);
  let findTop = function(obj) {
    if (!obj) return 0;
    return obj.offsetTop + findTop(obj.offsetParent);
  }
  let showPopin = function(v) {
    let side = ['top_left', 'mid_left', 'bottom_left'].includes(v.position) ? 'left' : 'right';
    let vpos = ['top_left', 'top_right'].includes(v.position) ? 'top' : (['bottom_left', 'bottom_right'].includes(v.position) ? 'bottom' : 'mid');

    let el = document.createElement('a');
    let col_l = document.createElement('span');
    let col_r = document.createElement('span');
    let icon = document.createElement('span');
    let label = document.createElement('span');
    let text = document.createElement('span');

    el.appendChild(col_l);
    el.appendChild(col_l);
    el.appendChild(col_r);
    col_l.appendChild(icon);
    col_l.appendChild(label);
    col_r.appendChild(text);

    el.id = 'dgkpopin';

    el.style.display = 'block';
    el.style.position = 'fixed';
    if (vpos == 'top') {
      el.style.top = '3em';
    } else if (vpos == 'bottom') {
      el.style.bottom = '3em';
    } else {
      el.style.top = '50%';
      el.style.marginTop = '-2em';
    }
    if (v.size == 'large') {
      el.style.fontSize = '19px';
    } else if (v.size == 'small') {
      el.style.fontSize = '12px';
    } else {
      el.style.fontSize = '15px';
    }
    el.style.height = '4em';
    el.style.lineHeight = '1';
    el.style.width = '19em';
    el.style.backgroundColor = v.backColor;
    if (v.outline) {
      el.style.border = '.15em solid ' + v.foreColor;
    }
    el.style.color = v.foreColor;
    el.style.fill = v.foreColor;
    el.style.boxShadow = '0px 0px 1em rgba(0,0,0,.2)';
    el.style.transition = 'transform 1s .3s ease-out';
    el.style.zIndex = 99999;
    if (side == 'left') {
      el.style.left = '0';
      el.style.borderRadius = '0 .4em .4em 0';
      el.style.transform = 'translateX(-15em)';
      el.style.borderLeft = 'none';
    } else {
      el.style.right = '0';
      el.style.borderRadius = '.4em 0 0 .4em';
      el.style.transform = 'translateX(15em)';
      el.style.borderRight = 'none';
    }

    col_l.style.display = 'block';
    col_l.style.position = 'absolute';
    col_l.style.width = '4em';
    col_l.style.height = '4em';
    col_l.style.top = '0';

    col_r.style.display = 'block';
    col_r.style.position = 'absolute';
    col_r.style.width = '15em';
    col_r.style.height = '4em';
    col_r.style.top = '0';
    if (side == 'left') {
      col_l.style.left = '15em';
      col_r.style.left = '0';
    } else {
      col_l.style.left = '0';
      col_r.style.left = '4em';
    }

    icon.style.display = 'block';
    icon.style.position = 'absolute';
    icon.style.fill = 'inherit';
    icon.style.width = '2.5em';
    icon.style.height = '2.5em';
    icon.style.top = '.4em';
    icon.style.left = '.75em';

    label.style.display = 'block';
    label.style.position = 'absolute';
    label.style.width = '100%';
    label.style.height = '1em';
    label.style.bottom = '.4em';
    label.style.left = '0';
    label.style.textAlign = 'center';
    label.style.fontSize = '.6em';
    label.style.textTransform = 'uppercase';

    text.style.display = 'block';
    text.style.position = 'absolute';
    text.style.width = '100%';
    text.style.height = '100%';
    text.style.top = '0';
    text.style.left = '0';
    text.style.textAlign = 'center';
    text.style.fontSize = '2em';
    text.style.lineHeight = '2';

    el.href = v.shopUrl;
    el.target = v.target == 'self' ? '_self' : '_blank';

    icon.innerHTML = '<svg style="width:100%;height:100%;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g><path d="M38.3,492.2c0,10.9,8.8,19.8,19.8,19.8H235V279.5H38.3V492.2z M277,512h176.9c10.9,0,19.8-8.8,19.8-19.8V279.5H277V512z    M483.6,131.1h-91.3c8.4-13.2,13.4-28.9,13.4-45.8c0-47.1-38.3-85.3-85.3-85.3c-25.6,0-48.7,11.4-64.3,29.3   C240.4,11.4,217.3,0,191.7,0c-47.1,0-85.3,38.3-85.3,85.3c0,16.8,4.9,32.5,13.4,45.8H28.4c-10.9,0-19.8,8.8-19.8,19.8v86.6H235   V131.1h42v106.4h226.3v-86.6C503.3,139.9,494.5,131.1,483.6,131.1z M235,128.6h-43.3c-23.9,0-43.3-19.4-43.3-43.3   S167.8,42,191.7,42S235,61.5,235,85.3V128.6z M320.3,128.6H277V85.3c0-23.9,19.4-43.3,43.3-43.3s43.3,19.4,43.3,43.3   S344.2,128.6,320.3,128.6z"></path></g></svg>';

    label.textContent = 'Gavekort';

    text.textContent = v.text;

    let popin_is_out = false;
    el.addEventListener('click', (e) => {
      if (!popin_is_out) {
        e.preventDefault();
        return false;
      }
    });

    el.addEventListener('mouseenter', () => {
      el.style.transform = 'translateX(0)';
      el.style.transition = 'transform .3s ease-in-out, color .2s ease-in-out, background-color .2s ease-in-out, fill .2s ease-in-out';
      if (v.outline) {
        el.style.backgroundColor = v.foreColor
        el.style.color = v.backColor
        el.style.fill = v.backColor
      }
      setTimeout(() => {
        popin_is_out = true;
      }, 500);
    });
    el.addEventListener('mouseleave', () => {
      popin_is_out = false;
      el.style.backgroundColor = v.backColor
      el.style.color = v.foreColor
      el.style.fill = v.foreColor
      el.style.transition = 'transform 1s .5s ease-in-out, color .2s ease-in-out, background-color .2s ease-in-out, fill .2s ease-in-out';
      if (side == 'left') {
        el.style.transform = 'translateX(-15em)';
      } else {
        el.style.transform = 'translateX(15em)';
      }
    });

    document.body.appendChild(el);

    if (['wiggle', 'beat'].includes(v.animation)) {
      icon.style.transition = 'transform .1s ease-in-out';
      let aniStep = 0;
      let steps = [5, -5, 10, -2.5, 0];
      if (v.animation == 'beat') {
        steps = [1.05, 1.04, 0.95, 1.05, 1.04, 0.95, 1];
      }
      let nextStep = () => {
        if (v.animation == 'beat') {
          icon.style.transform = 'scale(' + steps[aniStep] + ')';
        } else {
          icon.style.transform = 'rotate(' + steps[aniStep] + 'deg)';
        }
        aniStep++;
        if (aniStep == steps.length) {
          setTimeout(() => {
            aniStep = 0;
            nextStep();
          }, 5000 + Math.random() * 15000);
        }
      };
      icon.addEventListener('transitionend', nextStep);
      setTimeout(() => {
        nextStep();
      }, 5000);
    } else if (v.animation == 'spin') {
      icon.style.transition = 'all 1s ease-in-out';
      let curSpin = 0;
      let doSpin = () => {
        curSpin += 360;
        icon.style.transform = 'rotate(' + curSpin + 'deg)';
        setTimeout(() => {
          doSpin();
        }, 5000 + Math.random() * 15000);
      };
      setTimeout(() => {
        doSpin();
      }, 5000);
    }
  }
  if (w.dgkPopinData) {
    showPopin(w.dgkPopinData);
  }
})(window);